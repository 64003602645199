<template>
    <b-card>
        <!--  Info-->
        <table class="mt-2 mt-xl-0 w-100">
            <tr>
                <th class="pb-50">
                    <feather-icon icon="LayersIcon" class="mr-75" />
                    <span class="font-weight-bold">{{$t('country.name')}}</span>
                </th>
                <td class="pb-50">
                    {{ modelData.name }}
                </td>
            </tr>
            <tr>
                <th class="pb-50">
                    <feather-icon icon="CodeIcon" class="mr-75" />
                    <span class="font-weight-bold">{{$t('country.code')}}</span>
                </th>
                <td class="pb-50">
                    {{ modelData.code }}
                </td>
            </tr>
            <tr>
                <th class="pb-50">
                    <feather-icon icon="ZapIcon" class="mr-75" />
                    <span class="font-weight-bold">{{$t('country.status')}}</span>
                </th>
                <td class="pb-50 text-capitalize">
                    {{ $t(`status.${modelData.status}`) }}
                </td>
            </tr>
        </table>
    </b-card>
</template>

<script>
    import {
        BCard, BButton, BAvatar, BRow, BCol,
    } from 'bootstrap-vue'

    export default {
        components: {
            BCard, BButton, BRow, BCol, BAvatar,
        },
        props: {
            modelData: {
                type: Object,
                required: true,
            },
        },
        setup() {
            return {
            }
        },
    }
</script>

<style>
</style>
